<template>
    <o-data-lookup :data-object="dsShutdowns" :whereClause="`DescendantOrgUnit_ID = ${(OrgUnitId?OrgUnitId:context.id)}`" reloadOnWhereClauseChange>
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue" readonly>
        </template>
        <o-column field="ID" width="100"></o-column>
        <o-column field="Name" width="400"></o-column>
        <o-column field="OrgUnit" width="250"></o-column>
    </o-data-lookup>
</template>

<script setup>
import { getOrCreateDataObject } from 'o365.vue.ts';
import context from "o365.modules.Context.ts";

const props = defineProps({
    textInput: Boolean,
    is: String,
    IdPath: String,
    textInputValue: String,
    OrgUnitId: Number
});
 
const dsShutdowns = getOrCreateDataObject({
    id: 'dsShutdowns' + crypto.randomUUID(),
    viewName: 'aviw_Scope_ShutdownsLookup',
    maxRecords: 25,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields: [
        { name: "ID", type: "string" },
        { name: "Name", type: "string" },
        { name: "NameAndTitle", type: "string" },
        { name: "OrgUnit", type: "string" },
    ]
})      
  
</script>
